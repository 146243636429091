import {FC, useEffect, useState} from 'react';
import {MED_AI_API_URL} from '@/constants/global';
import {useABExperiment} from '@/context/ABExperimentContext';

export interface IMedAIChatbotProps {
  drugName?: string;
  genericName?: string;
  isModalOnMobile?: boolean;
  triggerId?: string;
  setId?: string;
}

export const MED_AI_CHATBOT_ID = 'med-ai-chatbot';
export const PRESCRIBER_AI_WC = 'prescriber-ai-wc';

const MedAIChatbot: FC<IMedAIChatbotProps> = ({
  drugName,
  genericName,
  isModalOnMobile = true,
  triggerId = '',
  setId,
}) => {
  const [component, setComponent] = useState<HTMLElement | null>(null);
  const {isNewVersion: newVersionDS} = useABExperiment();

  useEffect(() => {
    const existingWebComponent = document.querySelector(PRESCRIBER_AI_WC);
    if (existingWebComponent && drugName && genericName) {
      // If the Web Component already exists, we set the attributes directly
      existingWebComponent.setAttribute('drug-name', drugName);
      existingWebComponent.setAttribute('generic-name', genericName);
      return;
    }

    const webComponent = document.createElement(PRESCRIBER_AI_WC);

    if (drugName && genericName && setId) {
      webComponent.setAttribute('drug-name', drugName);
      webComponent.setAttribute('generic-name', genericName);
      webComponent.setAttribute('set-id', setId);
    }
    webComponent.setAttribute('is-modal-on-mobile', isModalOnMobile.toString());
    webComponent.setAttribute('trigger-id', triggerId);
    webComponent.setAttribute('fixed-height', 'false');
    webComponent.setAttribute('ai-professor-api-base-url', MED_AI_API_URL);
    webComponent.setAttribute(
      'background-color',
      newVersionDS ? 'bg-white' : 'bg-neutral-lighter-alt',
    );
    webComponent.setAttribute('cdn-folder', 'widgets');

    setComponent(webComponent);
  }, [drugName, genericName, isModalOnMobile, newVersionDS, setId, triggerId]);

  return (
    <div className='md:py-12' id={MED_AI_CHATBOT_ID}>
      <div id='web-component-container' className='relative'>
        {component ? (
          <div
            className='md:h-[510px]'
            dangerouslySetInnerHTML={{__html: component.outerHTML}}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MedAIChatbot;

/* eslint-disable import/named */
import {Icon, Text} from '@prescriberpoint/ui';
import {FC, useMemo} from 'react';
import {Link} from 'react-scroll';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';

interface BlackBoxWarningLinkProps {}
const BlackBoxWarningLink: FC<BlackBoxWarningLinkProps> = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);

  const hasWarning = useMemo(() => product?.label?.boxedWarning, [product]);

  return hasWarning ? (
    <Link
      to='boxed_warning'
      className='cursor-pointer'
      offset={-48}
      smooth='easeInOutQuart'
      duration={1000}>
      <div className='flex items-center space-x-1'>
        <Icon
          name='WarningShield'
          className='flex-items-center w-4 text-error'
        />
        <Text variant='primary' as='body-sm' weight='semibold'>
          BOXED WARNING
        </Text>
      </div>
    </Link>
  ) : (
    <Text as='body-sm' weight='semibold' className='text-neutral-darker/56'>
      NO BOXED WARNING
    </Text>
  );
};

export default BlackBoxWarningLink;

import {
  SearchFilterModal,
  toSnakeCase,
  IComboBoxOption,
  AlphabetTupleType,
  ISearchFilterOption,
} from '@prescriberpoint/ui';
import {FC, useCallback} from 'react';
import {UseLargeFilterReturn} from '@/hooks';

const scrollElementIntoContainerView = (
  targetElement: HTMLElement | null,
  containerElement: HTMLElement | null,
) => {
  if (!targetElement || !containerElement) return;

  const containerRect = containerElement.getBoundingClientRect();
  const elementRect = targetElement.getBoundingClientRect();

  const scrollTop =
    elementRect.top -
    containerRect.top +
    containerElement.scrollTop -
    (containerRect.height - elementRect.height) / 2;

  containerElement.scrollTo({
    top: scrollTop,
    behavior: 'smooth',
  });
};

const makeFilterOptionId = (option: ISearchFilterOption) =>
  `${toSnakeCase(option.value)}_modal_filter_option`;

const makeFilterLabelId = (label: string) => `${label}_search_filter_label`;

type LargeFilterModalProps = {
  largeFilter: UseLargeFilterReturn;
  title: string;
  handleMobileModalClose: () => void;
  handleMobileModalOpen: () => void;
};

const LargeFilterModal: FC<LargeFilterModalProps> = ({
  largeFilter,
  title,
  handleMobileModalClose,
  handleMobileModalOpen,
}) => {
  const {
    groupedLargeFilterOptionsByLetter,
    handleLargeFilterModalSearchOptionClick,
    handleLargeFilterModalOptionClick,
    isLargeFilterModalOpen,
    uncheckAllLargeFilterOptions,
    deleteFilterParams,
    resetLargeFilterModalOptions,
    handleLargeFilterModalClose,
    applyLargeFilter,
    largeFilterModalSelectedOptionValues,
    searchOptions,
  } = largeFilter;
  const handleSearchSelect = useCallback(
    (
      item: IComboBoxOption | null,
      filtersContainerRef: React.RefObject<HTMLElement>,
    ) => {
      if (item) {
        // Find the filter group containing the selected option
        const targetFilter = groupedLargeFilterOptionsByLetter.find((filter) =>
          filter.options.some(
            (option) => option.value.toLowerCase() === item.value.toLowerCase(),
          ),
        );
        if (targetFilter) {
          // Find the specific option
          const targetOption = targetFilter.options.find(
            (option) => option.value.toLowerCase() === item.value.toLowerCase(),
          );

          if (targetOption && filtersContainerRef.current) {
            // Add a small delay to ensure DOM is updated
            setTimeout(() => {
              const optionElement = document.getElementById(
                makeFilterOptionId(targetOption),
              );
              scrollElementIntoContainerView(
                optionElement,
                filtersContainerRef.current,
              );

              if (optionElement) {
                // Add temporary highlight
                optionElement.classList.add('ppt-bg-neutral-lighter');
                setTimeout(() => {
                  optionElement.classList.remove('ppt-bg-neutral-lighter');
                }, 2000);
              }
            }, 100);
          }
        }
      }
      handleLargeFilterModalSearchOptionClick(item);
    },
    [
      groupedLargeFilterOptionsByLetter,
      handleLargeFilterModalSearchOptionClick,
    ],
  );

  const handleAlphabetFilterClick = (
    alphabetTuple: AlphabetTupleType,
    filtersContainerRef: React.RefObject<HTMLElement>,
  ) => {
    const searchFilterLabelElement = document.getElementById(
      makeFilterLabelId(alphabetTuple[0]),
    );
    if (searchFilterLabelElement && filtersContainerRef.current) {
      setTimeout(() => {
        scrollElementIntoContainerView(
          searchFilterLabelElement,
          filtersContainerRef.current,
        );
      }, 100);
    }
  };

  const handleCloseModal = useCallback(() => {
    resetLargeFilterModalOptions();
    handleLargeFilterModalClose();
  }, [resetLargeFilterModalOptions, handleLargeFilterModalClose]);  

  const onGoBack = useCallback(() => {
    handleCloseModal();
    handleMobileModalOpen();
  }, [handleCloseModal, handleMobileModalOpen]);

  return (
    // @ts-expect-error error due to typescript not inferring extension
    <SearchFilterModal
      title={title}
      isOpen={isLargeFilterModalOpen}
      filters={groupedLargeFilterOptionsByLetter}
      makeFilterOptionId={makeFilterOptionId}
      makeFilterLabelId={makeFilterLabelId}
      closeModal={handleCloseModal}
      onGoBack={onGoBack}
      onFilterOptionClick={handleLargeFilterModalOptionClick}
      onResetFilters={() => {
        uncheckAllLargeFilterOptions();
        deleteFilterParams();
        handleLargeFilterModalClose();
        handleMobileModalClose();
      }}
      onApplyFilters={() => {
        applyLargeFilter(largeFilterModalSelectedOptionValues);
        handleLargeFilterModalClose();
        handleMobileModalClose();
      }}
      onSearchSelect={handleSearchSelect}
      searchOptions={searchOptions}
      onAlphabetFilterClick={handleAlphabetFilterClick}
    />
  );
};

export default LargeFilterModal;

import {Icon, IconButton, Text, Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {useCallback, useEffect, ChangeEvent} from 'react';
import {useSearchBox} from 'react-instantsearch';
import SelectedBrands from './SelectedBrands';
import {useSearchBrandsContext} from '@/context/SearchBrandsContext';
import {useUserAgentContext} from '@/context/UserAgentContext';

interface SearcherHeaderProps {
  query: string;
  setQuery: (_query: string) => void;
  onContinue: () => void;
}

const SearcherHeader = ({query, setQuery, onContinue}: SearcherHeaderProps) => {
  const {refine, query: currentRefinement} = useSearchBox();
  const {selectedResults, inputRef, setOpenSearch} = useSearchBrandsContext();
  const {isMobileOrTablet} = useUserAgentContext();

  const clearSearch = useCallback(() => {
    setQuery('');
    refine('');
    inputRef?.current?.focus();
  }, [setQuery, refine, inputRef]);

  const closeSearch = useCallback(() => {
    setQuery('');
    refine('');
    setOpenSearch(false);
  }, [refine, setOpenSearch, setQuery]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const search = e.target.value;
      setQuery(search);
      refine(search);
    },
    [setQuery, refine],
  );

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (query !== currentRefinement) {
      refine(query);
    }
  }, [currentRefinement, query, refine]);

  const haveSelectedResults = selectedResults.length > 0;

  const renderNoResults = useCallback(
    () =>
      !isMobileOrTablet && !currentRefinement ? (
        <div className='flex w-full flex-col gap-4'>
          <div className='mt-4 flex flex-col rounded-lg border border-dashed border-neutral-tertiary p-6 text-center'>
            <Text as='body-md' weight='extrabold' variant='secondary' size='md'>
              Select brands to continue
            </Text>
            <Text as='body-sm' weight='semibold' variant='secondary'>
              You can choose more than one brand
            </Text>
          </div>
          <Button
            id='continue'
            className='!rounded-lg font-bold'
            size='lg'
            stretched
            disabled={!haveSelectedResults}
            onClick={onContinue}>
            Continue
          </Button>
        </div>
      ) : null,
    [currentRefinement, isMobileOrTablet, onContinue, haveSelectedResults],
  );

  return (
    <div className='flex w-full flex-col'>
      {!isMobileOrTablet ? (
        <Text as='body-sm' weight='bold' size='xs' className='mb-1'>
          Brands
        </Text>
      ) : null}
      <div
        className={clsx(
          'flex h-14 w-full items-center gap-x-3 border-0 border-b border-solid border-neutral-light px-4 md:h-12 md:rounded-lg md:border',
          {'md:border md:border-theme-primary': currentRefinement},
        )}>
        {isMobileOrTablet ? (
          <IconButton
            className='flex h-full w-fit items-center'
            onClick={closeSearch}
            id='close_search'
            icon={
              <Icon
                name='LeftArrow'
                className='h-4 w-4.5 text-neutral-secondary'
              />
            }
          />
        ) : (
          <Icon
            name='SearchInfo'
            className='inline-flex w-5 items-center text-theme-primary'
          />
        )}
        <input
          data-clarity-unmask='true'
          ref={inputRef}
          className='h-full w-full border-0 bg-white text-base font-semibold text-neutral-primary outline-none placeholder:text-neutral-tertiary'
          placeholder='Search by Brand Name'
          type='search'
          value={currentRefinement}
          onChange={handleSearch}
          id='search_brand_input'
          data-hj-allow
          autoComplete='off'
        />
        {currentRefinement ? (
          <IconButton
            className='flex h-full w-fit items-center'
            onClick={isMobileOrTablet ? clearSearch : closeSearch}
            id='clear_search'
            icon={
              <Icon
                name='Cancel'
                className='inline-flex w-4 text-neutral-secondary'
              />
            }
          />
        ) : null}
      </div>
      {haveSelectedResults ? (
        <SelectedBrands onContinue={onContinue} />
      ) : (
        renderNoResults()
      )}
    </div>
  );
};

export default SearcherHeader;

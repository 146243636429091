import {Text, Toggle} from '@prescriberpoint/ui';

type ToggleFilterProps = {
  label: string;
  checked: boolean;
  onToggle: (_checked?: boolean) => void;
};

const ToggleFilter = ({label, checked, onToggle}: ToggleFilterProps) => (
  <div className='flex items-center justify-between gap-x-3'>
    <Text as='body-md' weight='extrabold' className='ppt-block'>
      {label}
    </Text>
    <Toggle checked={checked} onToggle={onToggle} size='md' />
  </div>
);

export default ToggleFilter;

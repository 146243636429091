import {ButtonIcon, Tooltip} from '@prescriberpoint/ui';
import {BookmarkCheckIcon, BookmarkIcon} from 'lucide-react';
import {FC} from 'react';
import {RegistrationModal} from '@/components';
import { useABExperiment } from '@/context/ABExperimentContext';
import {useCurrentDrug, useBookmarkPDP} from '@/hooks';
interface IBookmarkProps {
  readonly id: string;
}

const Bookmark: FC<IBookmarkProps> = ({id}) => {
  const currentDrug = useCurrentDrug();
  const {isNewVersion: showNewDesign} = useABExperiment();

  const {isFilled, renderModal, handleClick, closeModal} =
    useBookmarkPDP(currentDrug);

  return (
    <>
      {renderModal && (
        <RegistrationModal isGenericVersion onClose={closeModal} />
      )}
      <Tooltip
        position={showNewDesign ? 'top' : 'bottom'}
        className='!left-auto right-0 !translate-x-0'
        content={isFilled ? 'Remove from bookmarks' : 'Bookmark this therapy'}>
        <ButtonIcon
          id={id}
          test-id={isFilled ? 'bookmark-filled-icon' : 'bookmark-icon'}
          Icon={isFilled ? BookmarkCheckIcon : BookmarkIcon}
          onClick={handleClick}
          variant='default'
          size='md'
        />
      </Tooltip>
    </>
  );
};

export default Bookmark;

import {Text, TextType} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {v4 as uuidv4} from 'uuid';

export interface ListProps {
  title: string;
  items: string[];
  as?: TextType;
  titleType?: TextType;
  titleClassName?: ClassValue;
  listClassName?: ClassValue;
  textClassName?: ClassValue;
}

const List = ({
  title,
  items,
  as = 'body-md',
  titleType = 'body-md',
  listClassName,
  textClassName,
  titleClassName,
}: ListProps) =>
  items?.length > 0 ? (
    <div className='flex flex-col gap-y-2'>
      <Text as={titleType} weight='extrabold' className={clsx(titleClassName)}>
        {title}
      </Text>
      <ul className='m-0 p-0 pl-5'>
        {items.map((item) => (
          <li
            className={clsx(
              'm-0 list-disc p-0 marker:text-neutral-secondary',
              listClassName,
            )}
            key={`${title}-item-${uuidv4()}`}>
            <Text as={as} className={clsx(textClassName)} variant='secondary'>
              {item}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  ) : null;

export default List;

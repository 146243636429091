'use client';
import {MarketingFooter} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import {useToggle} from 'usehooks-ts';
import CookieConsent from './CookieConsent';
import {SHOW_COOKIE_POLICY_IN_FOOTER} from '@/constants/flags';
import {useFlag} from '@/context';
import {useUser} from '@/hooks';
import {useRouter} from '@/hooks/useRouter';

interface FooterProps {
  showSitemapLinks?: boolean;
  showCookiePolicyInFooter?: boolean;
  className?: ClassValue;
}

const Footer: FC<FooterProps> = ({className}) => {
  const [isModalActive, toggleModal] = useToggle();
  const policyInFooter = useFlag(SHOW_COOKIE_POLICY_IN_FOOTER);
  const router = useRouter();
  const {logged} = useUser();

  const shouldHideSitemapLinks = logged || router.pathname === '/home';

  return (
    <>
      {!logged ? (
        <MarketingFooter
          showSitemapLinks={!shouldHideSitemapLinks}
          showCookiePolicyInFooter={policyInFooter}
          onCookiePolicyClick={() => toggleModal()}
          className={clsx('w-full', className)}
        />
      ) : null}
      <CookieConsent isModalActive={isModalActive} toggleModal={toggleModal} />
    </>
  );
};

export default Footer;

import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import Link from 'next/link';
import {useEffect, useMemo, useState} from 'react';
import {Link as ScrollLink} from 'react-scroll';
import ChipSection from './ChipSection';
import MenuExtraData from './MenuExtraData';
import MedAIMobileCta from './PrescriberAIMobile/MedAIMobileCta';
import BackToTopButton from '@/components/BackToTopButton';
import {IS_PRODUCTION} from '@/constants/global';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug, useEnhanced} from '@/hooks';
import {useSectionsStatus} from '@/hooks/showSections/useSectionsStatus';
import {useRemsBySetId} from '@/queries';

const CustomLink = ({
  isProd,
  children,
  href,
  financialAssistance,
}: {
  isProd: boolean;
  href: string;
  children: React.ReactNode;
  financialAssistance: boolean;
}) => {
  const linkProps = {
    className: clsx(
      'no-underline',
      financialAssistance ? 'cursor-pointer' : 'cursor-default',
    ),
  };

  return isProd ? (
    <ScrollLink
      to={financialAssistance ? 'financial_assistance' : ''}
      smooth='easeInOutQuart'
      {...linkProps}>
      {children}
    </ScrollLink>
  ) : (
    <Link href={financialAssistance ? href : ''} {...linkProps}>
      {children}
    </Link>
  );
};

const PdpHeader = ({financialAssistance}: {financialAssistance?: boolean}) => {
  const {setId, drugName, genericName, slug} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();

  const {data, isFetching} = useRemsBySetId(setId);

  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const {disableBrandBookmarking, disableBrandShare} = useEnhanced(setId);
  const {showButtonAI} = useSectionsStatus();

  const showRemsInfo = !!(data && !isFetching);
  const showBookmark = !disableBrandBookmarking;
  const showShare = !disableBrandShare;

  const showMenuExtraData =
    [showRemsInfo, showBookmark, showShare].some(Boolean) && isMobileOrTablet;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Check if the user is scrolling up
      if (currentScrollY < lastScrollY && currentScrollY > 100) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const renderGenericName = useMemo(
    () => drugName.toLocaleLowerCase() !== genericName.toLocaleLowerCase(),
    [drugName, genericName],
  );
  return (
    <>
      <div
        id='pdp-header-v1'
        className='flex w-full max-w-[1360px] flex-col items-center space-y-1 md:flex-row md:justify-between md:space-y-0'>
        <div className='flex w-full items-center justify-between md:justify-start'>
          <div className='flex items-center justify-start space-x-1 md:space-x-4'>
            <CustomLink
              href={`/therapies/${slug}/financial-assistance`}
              isProd={IS_PRODUCTION}
              financialAssistance={!!financialAssistance}>
              <Text
                as={isMobileOrTablet ? 'headline-md' : 'headline-xl'}
                casing='title'
                tag='h1'
                weight='bold'>
                {drugName}
              </Text>
            </CustomLink>
            {renderGenericName ? (
              <Text as='body-md' weight='semibold' variant='secondary'>
                ({genericName})
              </Text>
            ) : null}
          </div>
          {showMenuExtraData ? (
            <MenuExtraData
              showRems={showRemsInfo}
              showBookmark={showBookmark}
              showShare={showShare}
            />
          ) : null}
        </div>
        <div className='flex w-full items-center justify-start space-x-2 md:justify-end md:space-x-3'>
          <ChipSection />
        </div>
      </div>
      <BackToTopButton
        className='fixed bottom-[70px] right-3.5 z-20 md:bottom-4 md:right-4'
        show={showBackToTopButton}
      />
      {isMobileOrTablet && showButtonAI ? <MedAIMobileCta /> : null}
    </>
  );
};

export default PdpHeader;

import {MenuList, MenuListItem, Modal, customEvent} from '@prescriberpoint/ui';
import {FC, useMemo, useRef} from 'react';
import {useToggle, useOnClickOutside} from 'usehooks-ts';
import {ChipPdp, Tooltip} from '@/components';
import {CEVENT_CHECK_DOSAGES_AND_STRENGTHS} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useCurrentSlug} from '@/hooks';
import {useLabel} from '@/queries';
import {sortedAlpha} from '@/utils';

const FILTER_DOSAGES_SBD = 'SBD';

const Dosages: FC = () => {
  const [toggledDosages, toggleDosages] = useToggle(false);
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const containerRef = useRef(null);

  const clickOutsideDesktopHandler = () => {
    if (!isMobileOrTablet && toggledDosages) {
      toggleDosages();
    }
  };

  useOnClickOutside(containerRef, clickOutsideDesktopHandler);

  const dosages = useMemo(() => {
    if (product?.prescribableNames?.length) {
      return product.prescribableNames.sort((a, b) => sortedAlpha(a, b));
    }

    // Fallback to product.names if prescribableNames is not available or is empty
    return (
      product?.names
        ?.filter((name) => name.tty === FILTER_DOSAGES_SBD)
        .map((name) => name.name) ?? []
    );
  }, [product?.names, product?.prescribableNames]);

  const {isMobileOrTablet} = useUserAgentContext();
  const handleOnClickMoreDosages = () => {
    customEvent(CEVENT_CHECK_DOSAGES_AND_STRENGTHS);
    toggleDosages();
  };

  const renderItems = () => (
    <div>
      {dosages.map((dosage: string) => (
        <MenuListItem
          size='lg'
          content={dosage}
          key={`dosage-list-${dosage}`}
          contentClassName='leading-normal min-h-auto capitalize'
        />
      ))}
    </div>
  );

  const renderContent = () => (
    <>
      {isMobileOrTablet ? (
        <MenuListItem
          type='titleSeparator'
          textCenter={false}
          content='Dosage Forms & Strengths'
        />
      ) : null}
      {renderItems()}
    </>
  );

  if (dosages.length === 0) {
    return null;
  }

  return (
    <div className='relative'>
      <ChipPdp label='DOSAGE & STRENGTHS' onClick={handleOnClickMoreDosages} />
      {toggledDosages ? (
        <>
          {isMobileOrTablet ? (
            <Modal
              isOpen={isMobileOrTablet}
              closeModal={toggleDosages}
              showCloseButton
              bodyClassName='p-0'
              position='side'
              showConfirm={false}
              showCancel={false}>
              {renderContent()}
            </Modal>
          ) : (
            <div className='absolute right-0 top-11 md:min-w-[325px]'>
              <Tooltip
                isOpen={!isMobileOrTablet}
                handleClose={toggleDosages}
                renderUnderButton>
                {/* eslint-disable deprecation/deprecation */}
                <MenuList type='common'>{renderContent()}</MenuList>
              </Tooltip>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Dosages;

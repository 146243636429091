import {Breadcrumb} from '@prescriberpoint/ui';
import React from 'react';

const FinancialAssistanceBreadCrumb = ({
  slug,
  drugName,
  programName,
}: {
  slug: string;
  drugName: string;
  programName?: string;
}) => (
    <div className='-ml-2'>
      <Breadcrumb
        crumbs={[
          {text: 'Home', href: '/home'},
          {text: drugName, href: `/therapies/${slug}`},
          {
            text: 'Financial Assistance',
            href: `/therapies/${slug}/financial-assistance`,
          },
          ...(programName ? [{text: programName ?? 'Program Details'}] : []),
        ]}
      />
    </div>
  );

export default FinancialAssistanceBreadCrumb;

import {FC} from 'react';
import {useHits} from 'react-instantsearch';
import {CustomHighlight} from './CustomHighlight';

interface HitsProps {
  onHitClick: (_slug: string) => void;
}

const Hits: FC<HitsProps> = ({onHitClick}) => {
  /* eslint-disable-next-line deprecation/deprecation */
  const {hits} = useHits(); // NOSONAR hits is deprecated

  return (
    <>
      {hits?.length > 0 ? (
        hits.map((hit: any) => (
          <button
            className='flex h-9 items-center bg-transparent p-2 text-neutral-primary hover:bg-neutral-lighter'
            key={`hit-${hit.slug}`}
            onClick={() => onHitClick(hit.slug)}>
            <CustomHighlight attribute='label_title' hit={hit} />
          </button>
        ))
      ) : (
        <div className='flex h-9 items-center p-2 text-neutral-primary'>
          No results...
        </div>
      )}
    </>
  );
};

export const CustomHits = Hits;
